import { useState, useRef, useEffect } from 'react';
import {
  Paper,
  InputBase,
  Divider,
  Typography,
  Grid,
  ClickAwayListener,
  Box,
  Fade,
  CircularProgress,
  Button,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { CloseIcon } from '@iconicicons/react';
import {
  OutlinedFlagRounded,
  RadioButtonUncheckedRounded,
  TrainRounded,
  CalendarToday,
  ConfirmationNumberRounded,
  FlightTakeoffRounded,
  FlightLandRounded,
  EastRounded,
} from '@mui/icons-material';
import { Select as SelectAirportCity } from 'react-select-virtualized';
import { airports } from '@pilotplans/aviation-data';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import IconsDrawer from '../IconsDrawer';
import DateTimePicker from '../../../molecules/DatePickers/DateTimePicker';
import { PlacesSearchBar } from '../SearchBar';
import {
  getTextWidth,
  parseISODate,
  removeTimezoneOffset,
} from '../../../../utils';
import { LocationPinIcon } from '../../../atoms/Icon';
import { useMapUtils } from '../../organisms/Map';
import BookingsHeader from '../../../molecules/BookingsHeader';
import actions from '../../../../redux/actions';
import { OrangeOutlinedButton } from '../../../atoms/Button/index';
import { useTour } from '../../../molecules/Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';
// import { updateActivity } from '../../../../redux/slices/Activity';
import { deleteMapPin } from '../../../../redux/slices/MapV2';
import { updateItem } from '../../../../redux/slices/Item';

const airportsJSON = airports.toJSON();

const customStyles = {
  input: (provided) => ({
    ...provided,
    fontSize: '12px',
    fontWeight: 300,
    color: '#222',
    margin: '0px',
    padding: '0px',
  }),
  menu: (provided) => ({
    ...provided,
    color: '#222',
    backgroundColor: '#F4F4F4 !important',
    zIndex: 9999,
    whiteSpace: 'nowrap',
    width: '100%',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  singleValue: (provided) => {
    return { ...provided, margin: '0px' };
  },
  container: (base) => {
    return {
      ...base,
      borderRadius: '4px',
      padding: '0px',
      width: '100%',
      minWidth: '50px',
      border: '1px solid #ED702E',
      overflow: 'none !important',
      '&:hover': {
        cursor: 'pointer',
      },
    };
  },
  valueContainer: (base) => ({
    ...base,
    fontSize: '12px',
    fontWeight: 300,
    color: '#222',
    paddingLeft: '4px',
    margin: '0px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none',
    width: '0',
    height: '0',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '12px',
    color: '#8A8A8A',
    margin: '0px',
  }),
  control: (_, { selectProps: { width } }) => ({
    width,
  }),
};

const useStyles = makeStyles(({ breakpoints, typography }) => ({
  blade: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F4F4F4',
    border: '1px solid #DEDDDD',
    borderRadius: '4px',
    // padding: '4px 8px',
    // overflow: 'hidden',
  },
  accomodationBlade: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F4F4F4',
    border: '1px solid #DEDDDD',
    borderRadius: '4px',
    padding: '4px 8px',
    overflow: 'hidden',
  },
  font: {
    color: '#222222',
    fontSize: '12px',
  },
  inputPlaceholder: {
    padding: '2px 4px',
    '&::placeholder': {
      color: '#8A8A8A',
      opacity: 1,
    },
  },
  grayText: {
    textTransform: 'uppercase',
    color: '#8A8A8A',
    fontSize: '12px',
    display: 'inline-flex',
  },
  defaultState: {
    color: '#8A8A8A',
    fontSize: '1em',
  },
  accomodationActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1%',
  },
  activeText: {
    fontSize: '1em',
    color: '#222',
  },
  addressHeader: {
    marginRight: 8,
    padding: '2px 6px',
    whiteSpace: 'nowrap',
    alignSelf: 'center',
    color: '#4E4E4E',
    fontSize: '0.75rem',
    display: 'flex',
    borderRadius: 4,
    backgroundColor: '#E7F0F1',
    '&:hover': {
      cursor: 'pointer',
      color: '#4E4E4E',
      backgroundColor: '#D3DFE1',
    },
    '&:active': {
      cursor: 'pointer',
      color: '#4E4E4E',
      backgroundColor: '#C6D4D6',
    },
    [breakpoints.down('md')]: {
      maxWidth: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  lineContainer: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    justifyContent: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    transform: '',
    color: '#4e4e4e',
    margin: 4,
    marginTop: 0,
    height: 14,
    width: 14,
  },
  flightIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  line: {
    backgroundImage: 'linear-gradient(#4E4E4E 60%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'right',
    backgroundSize: '2px 12px',
    backgroundRepeat: 'repeat-y',
    width: '2px',
    height: '100%',
  },
  citySelectorContainer: {
    display: 'flex',
    width: '100%',
    borderRadius: 4,
    alignItems: 'center',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    padding: '2px 4px',
    border: '1px solid #DEDDDD',
    minWidth: '50px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  defaultCityState: {
    color: '#8A8A8A',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  searchBarBlured: {
    display: 'flex',
    overflow: 'hidden',
    minWidth: '40%',
    maxWidth: '250px',
  },
  searchBarActive: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
  },

  // datepicker
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #DEDDDD',
    padding: '1px 6px',
    fontSize: 12,
    borderRadius: 4,
    color: '#222',
    cursor: 'pointer',
    alignItems: 'center',
    marginLeft: '8px',
  },
  date: {
    marginLeft: 8,
    fontSize: 12,
    color: '#222',
    whiteSpace: 'nowrap',
  },

  toggleBlade: {
    fontSize: 12,
    color: '#ED702E',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    minWidth: getTextWidth('Try Auto Flight Search', '12px Inter'),
    textDecoration: 'underline',
  },
  flightInput: {
    border: '1px solid #DEDDDD',
    padding: '2px 4px',
    fontSize: 12,
    borderRadius: 4,
    color: '#222',
    marginLeft: 4,
    '&:focus-within': {
      border: '1px solid #ED702E',
    },
  },
  transportIcon: {
    color: '#4e4e4e',
    margin: 0,
    marginRight: 4,
    height: 16,
    width: 16,
  },

  bladeTop: {
    backgroundColor: '#FFFFFF',
    outline: '1px solid #D9D9D9',
    borderRadius: '2px 2px 4px 4px',
    padding: '3px 0.5%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      outlineColor: '#ED702E',
    },
  },

  focused: {
    outlineColor: '#ED702E',
  },

  activiityBladeTitle: {
    backgroundColor: '#FFFFFF',
    padding: '3px',
  },

  accomodationBladeTitle: {
    ...typography.h4,
  },

  activityIcon: () => ({
    color: '#222',
    marginLeft: '4px',
  }),
  tooltip: {
    backgroundColor: '#4E4E4E',
    border: 'none',
    fontSize: 12,
    borderRadius: '4px',
    padding: '2px 4px',
    marginTop: '15px',
  },
  tooltipArrow: {
    border: 'none',
    color: '#4E4E4E',
  },
}));

function AccomodationDatePicker({ defaultDate, updateDate, minDate, maxDate }) {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(
    defaultDate ? parseISODate(defaultDate) : null
  );
  const classes = useStyles();
  const [target, setTarget] = useState(null);

  // TODO: use the function from utils once merged.
  const formatDate = (newDate) => {
    return format(newDate, "MMM d  -  h:mmaaaaa'm'");
  };

  const handleSubmit = (newDate) => {
    setDate(newDate);
    updateDate(newDate);
  };
  const handleOpen = (e) => {
    setTarget(e?.currentTarget || null);
    setOpen(true);
  };

  return (
    <div style={{ display: 'flex', marginLeft: '1%' }}>
      <DateTimePicker
        open={open}
        handleClose={() => setOpen(false)}
        handleSubmit={(newDate) => handleSubmit(newDate)}
        handleDateChange={(newDate) => setDate(newDate)}
        selectedDate={date}
        minDate={minDate}
        maxDate={maxDate}
        anchorEl={target}
      />
      <div className={classes.dateContainer} onClick={handleOpen}>
        <CalendarToday
          style={{
            height: 16,
            width: 16,
            color: '#222',
            marginRight: 4,
          }}
        />
        {date ? (
          <Typography className={classes.activeText}>
            {
              // using 'replace' to force whitespace rendering
              formatDate(date).replace(/ /g, '\u00a0')
            }
          </Typography>
        ) : (
          <Typography className={classes.defaultState}>00/00/00</Typography>
        )}
      </div>
    </div>
  );
}

export function TransportationDatePicker({
  handleSubmit = () => {},
  minDate,
  maxDate,
  disableTime = false,
  defaultDate = null,
  triggerOpen = false,
  setTriggerOpen = () => {},
  flightVersion,
}) {
  const [date, setDate] = useState(
    defaultDate ? parseISODate(defaultDate, flightVersion) : null
  );
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const userObj = useSelector((state) => state.Auth.userData);
  const is24HourFormat = userObj.user.is24HourTime;
  const classes = useStyles();

  // TODO: use the function from utils once merged.
  const formatDate = (unformattedDate) => {
    try {
      return format(unformattedDate, 'MMM dd');
    } catch (err) {
      return null;
    }
  };

  const formatDateTime = (unformattedDate) => {
    if (date) {
      const dateFormat = is24HourFormat ? 'MMM dd - HH:mm' : 'MMM dd - hh:mmaa';
      return format(parseISODate(unformattedDate, flightVersion), dateFormat);
    }
    return null;
  };

  useEffect(() => {
    if (triggerOpen) {
      setOpen(true);
      setTriggerOpen(false);
    }
  }, [triggerOpen]);

  const handleOpen = (e) => {
    setTarget(e?.currentTarget || null);
    setOpen(true);
  };

  return (
    <div>
      <DateTimePicker
        open={open}
        handleClose={() => setOpen(false)}
        handleSubmit={(newDate) => {
          setDate(newDate);
          handleSubmit(newDate);
        }}
        handleDateChange={(newDate) => setDate(newDate)}
        selectedDate={date}
        minDate={minDate}
        maxDate={maxDate}
        disableTime={disableTime}
        anchorEl={target}
        onOpen={handleOpen}
      />
      <div className={classes.dateContainer} onClick={handleOpen}>
        <CalendarToday
          style={{
            height: 14,
            width: 14,
            color: '#4E4E4E',
          }}
        />
        <div
          className={classes.date}
          style={{ color: date ? '#222' : '#8A8A8A' }}>
          {date && new Date(date) !== 'Invalid Date'
            ? disableTime
              ? formatDate(date)
              : formatDateTime(date)
            : 'Enter Date'}
        </div>
      </div>
    </div>
  );
}

function TransportationCityPicker({
  placeholder,
  onChange,
  defaultAirport,
  transportationType,
  handleFocus,
  handleBlur,
}) {
  const [city, setCity] = useState(
    transportationType === 'flight'
      ? defaultAirport
        ? airports.findWhere({
            [defaultAirport?.length === 3 ? 'iata' : 'icao']: defaultAirport,
          })?.attributes
        : null
      : defaultAirport
  );
  const [focus, setFocus] = useState(false);
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {' '}
      {focus ? (
        transportationType === 'flight' ? (
          <SelectAirportCity
            id="city-select"
            grouped={false}
            autoFocus
            minimumInputSearch={2}
            placeholder={placeholder}
            formatOptionLabel={(props) => {
              return <span>{`${props.name}, ${props.city}`}</span>;
            }}
            value=""
            options={airportsJSON.map((airport) => ({
              ...airport,
              label: `${airport.city} (${airport.iata})`,
            }))}
            onChange={(airport) => {
              setCity(airport);
              onChange(airport);
              setFocus(false);
            }}
            onFocus={handleFocus}
            onBlur={() => {
              setFocus(false);
              handleBlur();
            }}
            menuPortalTarget={document.body}
            styles={customStyles}
          />
        ) : (
          <PlacesSearchBar
            fullWidth
            id="transportation"
            onChange={(searchResult) => {
              setCity(searchResult);
              onChange(searchResult);
            }}
            tripLocation={false}
            placeholder={placeholder}
            handleSelect={() => setFocus(false)}
            popperPlacement="bottom"
            CustomSearchBar={InputBase}
            value={city}
            searchBarProps={{
              autoFocus: true,
              style: {
                borderRadius: '4px',
                border: '1px solid #ED702E',
                fontSize: '12px',
                lineHeight: 'inherit',
                display: 'flex',
              },
              inputProps: {
                className: [classes.inputPlaceholder],
                margin: 0,
              },
              placeholder,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              },
              onFocus: handleFocus,
              onBlur: () => {
                setFocus(false);
                handleBlur();
              },
            }}
          />
        )
      ) : (
        <div
          className={classes.citySelectorContainer}
          onClick={() => setFocus(true)}>
          {city ? (
            <Grid container direction="column">
              {transportationType === 'flight' ? (
                <Typography
                  style={{
                    fontSize: 12,
                  }}>
                  {city.city} {city.iata !== '\\N' && `(${city.iata})`}
                </Typography>
              ) : (
                <Typography style={{ fontSize: 12 }}>{city}</Typography>
              )}
            </Grid>
          ) : (
            <Typography
              style={{ fontSize: 12 }}
              className={classes.defaultCityState}>
              {placeholder}
            </Typography>
          )}
        </div>
      )}{' '}
    </div>
  );
}

function InlineBlade({
  id,
  open,
  setOpen = () => {},
  name,
  updateNameCallback = () => {},
  notes,
  updateNotesCallback = () => {},
  hasDate,
  dateVal = null,
  updateDateCallback = () => {},
  hasCost,
  costVal = null, // costVal object should have the following properties : amount, currency and per
  updateCostCallback = () => {},
  hasLink,
  isLoading = false, // shows loader in inputfield when this is true
  linkVal = null,
  updateLinkCallback = () => {},
  isAccomodation = false,
  hasCustomCalendar = false,
  hasLocation = false,
  updateLocationCallback = () => {},
  address = null,
  setAddress = () => {},
  // props specific to accomodation
  accomodationProps = {
    checkinDate: null,
    checkoutDate: null,
    updateCheckinDate: () => {},
    updateCheckoutDate: () => {},
    isBooking: false,
    bookingId: null,
  },
  updateAccommodation = () => {},
  placeholder = '',
  isFlight = false,
  transportationType = null,
  disableTransition = false,
  flightProps = {},
  isActivity, // Optional: indicates whether the inline blade is used for the activity component
  custom = false,
  children,
  fileAttachmentModalProps,
  mapPinId,
  isNewActivity,
  addActivityBelow,
  handleEnterPress,
  locationBias,
  activityVersion,
  trackFileView = () => {},
  className,
}) {
  const classes = useStyles();
  const { slug: tripId } = useParams();
  const [bladeTitle, setBladeTitle] = useState(
    transportationType === 'flight' ? 'Manual Flight Entry' : name
  );
  const [bladeNotes, setBladeNotes] = useState(notes);
  const [bladeLocation, setBladeLocation] = useState(address);
  const [transitionStarted, setTransitionStarted] = useState(false);
  const [locationInput, setLocationInput] = useState(false);
  const [bladePinChanged, setBladePinChanged] = useState(false);
  const [isBladeTitleFocused, setBladeTitleFocused] = useState(false);
  const [addressHovered, setAddressHovered] = useState(false);
  const bladeTitleRef = useRef(null);
  const bladeSearchRef = useRef(null);

  // checking for flightNumber input
  const [flightNumber, setFlightNumber] = useState(
    flightProps?.content?.flightNumber
  );
  const [transportationPickerActive, setTransportationPickerActive] =
    useState(null);

  // useOutsideAlerter(wrapperRef, setOpen, drawerRef);

  // states for accomodation panel
  const [showCustomEntry, setCustomEntry] = useState(false);
  const [isActive, setActive] = useState(false);

  const {
    inProgress: tourInProgress,
    tourName,
    currentStep: tourCurrentStep,
    isOpen: tourOpen,
    currentStepName: tourStepName,
  } = useSelector((state) => state.View.tour);

  const { getTour } = useTour();

  const DepartureCityIcon = ({ isIconActive }) => {
    const iconProps = {
      className: classes.transportIcon,
      fontSize: 'small',
      style: {
        color: isIconActive ? '#ED702E' : '#4E4E4E',
      },
    };
    let res;
    switch (transportationType) {
      case 'train':
      case 'bus':
        res = <TrainRounded {...iconProps} />;
        break;
      case 'other':
        res = <RadioButtonUncheckedRounded {...iconProps} />;
        break;
      default:
        res = <FlightTakeoffRounded {...iconProps} />;
    }
    return res;
  };

  const { handleLocationSelect } = useMapUtils();
  const handlePlacesSearchSelect = async (option, onlyLocation = false) => {
    const { title = '', ...location } = await handleLocationSelect(
      option.place_id,
      mapPinId,
      isAccomodation ? 'ACCOMMODATION' : 'ACTIVITY'
    );
    return {
      ...(onlyLocation ? {} : { name: title }),
      ...location,
    };
  };

  useEffect(() => {
    if (bladeLocation !== address) {
      setBladeLocation(address);
    }
  }, [address]);

  useEffect(() => {
    if (open && bladeTitleRef.current && !bladeTitle) {
      bladeTitleRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (isNewActivity) {
      bladeSearchRef?.current?.focus();
    }
  }, []);

  const showAddress = address && address.trim() !== '';
  // conditional searchbar props depending on whether the address input is being used / title input is being used
  const bladeSearchbarProps = locationInput
    ? {
        main: {
          autoFocus: true,
          id: `location-search-${id}`,
          handleSelect: async (option) => {
            // update only the address field
            const location = await handlePlacesSearchSelect(option, true);
            updateLocationCallback(location);
            bladeSearchRef?.current?.blur();
          },
          value: bladeLocation,
          onChange: (title) => setBladeLocation(title),
        },
        searchbar: {
          autoFocus: true,
          startAdornment: (
            <LocationPinIcon
              style={{
                height: 16,
                width: 16,
                marginRight: 4,
              }}
            />
          ),
          onBlur: () => {
            if (locationInput) {
              setLocationInput(false);
              setActive(false);
              setBladeTitleFocused(false);
            }
          },
        },
      }
    : {
        main: {
          id: `${isAccomodation ? 'accommodation' : 'activity'}-${id}`,
          handleSelect: async (option) => {
            const { mapPin = null, ...location } =
              await handlePlacesSearchSelect(option, false);
            if (isAccomodation) {
              updateAccommodation({
                ...location,
                ...(mapPin ? { mapPin: mapPin?.id, mapPinObj: mapPin } : {}),
              });
            } else {
              updateLocationCallback({
                ...location,
                title: location.name,
                mapPin,
              });
            }
            setBladePinChanged(true);
            bladeSearchRef?.current?.blur();
          },
          value: bladeTitle,
          onChange: (title) => setBladeTitle(title),
        },
        searchbar: {
          onBlur: () => {
            if (bladeTitle !== name && !bladePinChanged) {
              updateNameCallback(bladeTitle);
            }
            setActive(false);
            setBladeTitleFocused(false);
          },
        },
      };

  function FlightSearchToggle() {
    return (
      <Typography
        className={classes.toggleBlade}
        onMouseDown={(e) => {
          e.stopPropagation();
          flightProps?.setOpenAutomatedFlightBlade(true);
        }}>
        Try Auto Flight Search
      </Typography>
    );
  }

  const dispatch = useDispatch();

  const getIataCode = (airportCode) => {
    try {
      if (!airportCode || airportCode === '\\N') return '';
      if (airportCode?.length === 3) return airportCode;

      return (
        airports?.findWhere({
          icao: airportCode,
        })?.attributes?.iata || ''
      );
    } catch (err) {
      return '';
    }
  };

  const handleBookingsOpen = () => {
    const outboundDate =
      flightProps?.content?.startDate || flightProps?.content?.endDate;
    dispatch(
      actions.View.setBookingsView({
        isInView: true,
        bookingsProps: {
          outboundDate:
            (outboundDate && format(new Date(outboundDate), 'yyyy-MM-dd')) ||
            '',
          departureAirport: getIataCode(flightProps?.content?.toAirport) || '',
          arrivalAirport: getIataCode(flightProps?.content?.fromAirport) || '',
        },
      })
    );
  };

  const handleDeleteMapPin = (type) => {
    dispatch(
      deleteMapPin({
        context: {
          tripId,
        },
        variables: {
          id: mapPinId,
        },
      })
    );
    if (type === 'activity' || type === 'accommodation') {
      dispatch(
        updateItem({
          variables: {
            id,
            content: {
              address: null,
            },
          },
        })
      );
    }
    setAddress(null);
  };

  useEffect(() => {
    if (open && tourInProgress) {
      if (getTour().isTourActive('sampleTripFlowMobile')) {
        if (tourCurrentStep === 3) {
          getTour().goToNextStep().openTour({
            delay: 100,
          });
        }
        if (tourCurrentStep === 4) {
          getTour().openTour({
            delay: 100,
          });
        }
      } else if (getTour().isTourActive('quickStartFlowMobile')) {
        getTour()
          .onActiveStep(stepName.FIRST_ACITVITY_OPEN_DETAILS_MOBILE)
          .goToNextStep();
      } else if (
        getTour().isTourActive([
          'emptyTripFlowWeb',
          'blankTripFlowWeb',
          'quickStartFlowWeb',
          'sampleTripFlowWeb',
        ])
      ) {
        if (
          tourStepName === stepName.FIRST_ACITVITY_OPEN_DETAILS &&
          isActivity
        ) {
          // dispatch(actions.View.setTourOpen(true));
          getTour().openTour({
            delay: 1000,
          });
        }
        if (tourStepName === stepName.FIRST_ACTIVITY_HOVER_INFO && isActivity) {
          getTour().closeTour();
          getTour().goToNextStep().openTour({
            delay: 1000,
          });
        }
      }
    }
  }, [tourInProgress, tourName, tourCurrentStep, open, tourOpen]);
  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          getTour()
            .onActiveTour('quickStartFlowMobile')
            .onActiveStep(stepName.FIRST_ACITVITY_OPEN_DETAILS)
            .closeTour()
            .goToNextStep();

          if (disableTransition) {
            setOpen(false);
            return;
          }
          setTransitionStarted(true);
          setTimeout(() => {
            setOpen(false);
            setTransitionStarted(false);
          }, 300);
        }
      }}>
      <Fade
        in={open && !transitionStarted}
        timeout={disableTransition ? 0 : 300}>
        <Paper
          className={`${
            isAccomodation || isFlight
              ? classes.accomodationBlade
              : classes.blade
          }`}
          variant="outlined">
          <Box>
            {custom ? (
              children
            ) : (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  // overflow: 'hidden',
                  width: '100%',
                  pointerEvents: accomodationProps?.isBooking ? 'none' : 'auto',
                }}>
                <Box
                  style={{
                    display: 'flex',
                    width: '100%',
                  }}>
                  <Box
                    onBlur={() => setActive(false)}
                    onFocus={() => setActive(true)}
                    style={{
                      display: 'flex',
                      minWidth: isActive || !showAddress ? '100%' : 'auto',
                      width: '100%',
                    }}>
                    {!isFlight ? (
                      !isAccomodation ? (
                        <Box
                          className={`${classes.bladeTop} ${
                            isBladeTitleFocused ? classes.focused : ''
                          }`}>
                          <OutlinedFlagRounded
                            fontSize="16px"
                            className={classes.activityIcon}
                          />
                          <PlacesSearchBar
                            isActive={isActive}
                            tripLocation={false}
                            key={locationInput}
                            placeholder={
                              locationInput
                                ? 'Destination name or address...'
                                : 'Name your activity...'
                            }
                            {...bladeSearchbarProps.main}
                            popperPlacement="bottom"
                            CustomSearchBar={InputBase}
                            inputRef={bladeSearchRef}
                            locationBias={locationBias}
                            searchBarProps={{
                              className: classes.activityBladeTitle,
                              fullWidth: true,
                              style: {
                                // margin: '0px 4px',
                              },
                              placeholder: locationInput
                                ? 'Destination name or address...'
                                : 'Name your activity...',
                              onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                  if (isNewActivity) {
                                    handleEnterPress(
                                      bladeSearchRef?.current?.value
                                    );
                                  } else {
                                    updateNameCallback(
                                      bladeSearchRef?.current?.value
                                    );
                                    if (bladeSearchRef?.current?.value !== '') {
                                      addActivityBelow();
                                    }
                                    setOpen(false);
                                  }
                                  bladeSearchRef?.current?.blur();
                                }
                              },
                              onFocus: () => {
                                setBladeTitleFocused(true);
                              },
                              ...bladeSearchbarProps.searchbar,
                              inputProps: {
                                style: {
                                  padding: 3,
                                  marginLeft: '8px',
                                },
                              },
                            }}
                          />
                          {address && (
                            <Tooltip
                              classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.tooltipArrow,
                              }}
                              arrow
                              title="Location"
                              enterDelay={500}
                              disableInteractive
                              TransitionComponent={Fade}>
                              <Typography
                                className={classes.addressHeader}
                                onClick={() => setLocationInput(true)}
                                onMouseEnter={() => setAddressHovered(true)}
                                onMouseLeave={() => setAddressHovered(false)}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}>
                                <span>{address}</span>
                                {addressHovered && (
                                  <CloseIcon
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      marginLeft: '4px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteMapPin('activity');
                                    }}
                                  />
                                )}
                              </Typography>
                            </Tooltip>
                          )}
                        </Box>
                      ) : (
                        <PlacesSearchBar
                          isActive={isActive}
                          tripLocation={false}
                          locationBias={locationBias}
                          key={locationInput}
                          placeholder="Search for an address"
                          {...bladeSearchbarProps.main}
                          popperPlacement="bottom"
                          CustomSearchBar={InputBase}
                          inputRef={bladeSearchRef}
                          searchBarProps={{
                            className: classes.accomodationBladeTitle,
                            fullWidth: true,
                            style: {
                              // margin: '0px 4px',
                            },
                            placeholder: 'Search for an address',
                            onKeyDown: (e) => {
                              if (e.key === 'Enter') {
                                setOpen(false);
                                bladeSearchRef?.current?.blur();
                              }
                            },
                            ...bladeSearchbarProps.searchbar,
                            inputProps: {
                              style: {
                                padding: '4px 0px 5px',
                                marginLeft: '8px',
                              },
                            },
                          }}
                        />
                      )
                    ) : transportationType === 'flight' ? (
                      flightProps?.showHeader ? (
                        <BookingsHeader
                          openBookingsModal={handleBookingsOpen}
                        />
                      ) : null
                    ) : (
                      <InputBase
                        inputRef={bladeTitleRef}
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            bladeTitleRef?.current?.blur();
                          }
                        }}
                        value={bladeTitle}
                        fullWidth
                        placeholder={placeholder}
                        onChange={(e) => setBladeTitle(e.target.value)}
                        onBlur={(e) => updateNameCallback(e.target.value)}
                        style={{
                          margin: '0 4px',
                          minWidth: '100%',
                          ...(transportationType === 'flight'
                            ? {
                                pointerEvents: 'none',
                                fontWeight: 700,
                              }
                            : {}),
                        }}
                        inputProps={{
                          className: classes.font,
                          style: {
                            fontSize: transportationType === 'flight' ? 14 : 16,
                          },
                        }}
                        startAdornment={
                          isActivity && (
                            <OutlinedFlagRounded
                              style={{
                                fontSize: 16,
                                color: '#222222',
                                marginRight: '8px',
                              }}
                            />
                          )
                        }
                        endAdornment={
                          <Fade
                            in={isLoading}
                            style={{
                              transitionDelay: isLoading ? '800ms' : '0ms',
                            }}
                            unmountOnExit>
                            <CircularProgress
                              size={20}
                              style={{ marginRight: 4 }}
                            />
                          </Fade>
                        }
                      />
                    )}
                  </Box>
                  {isActive || isFlight || !showAddress ? null : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          minWidth: `calc(100% - ${
                            getTextWidth(bladeTitle, '16px Inter') +
                            16 +
                            (isAccomodation ? 110 : 0)
                          }px)`,
                        }}>
                        {(isFlight || isAccomodation) && address && (
                          <Tooltip
                            classes={{
                              tooltip: classes.tooltip,
                              arrow: classes.tooltipArrow,
                            }}
                            arrow
                            title="Location"
                            enterDelay={500}
                            disableInteractive
                            TransitionComponent={Fade}>
                            <Typography
                              className={classes.addressHeader}
                              onClick={() => setLocationInput(true)}
                              onMouseEnter={() => setAddressHovered(true)}
                              onMouseLeave={() => setAddressHovered(false)}
                              style={{ display: 'flex', alignItems: 'center' }}>
                              <span>{address}</span>
                              {addressHovered && (
                                <CloseIcon
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    marginLeft: '4px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteMapPin(
                                      isFlight ? 'flight' : 'accommodation'
                                    );
                                  }}
                                />
                              )}
                            </Typography>
                          </Tooltip>
                        )}
                      </div>
                      {isAccomodation && !accomodationProps?.isBooking ? (
                        <div style={{ flex: 0, marginLeft: 'auto' }}>
                          <OrangeOutlinedButton
                            onClick={accomodationProps?.handleOpenBookingsModal}
                            endIcon={
                              <EastRounded sx={{ height: 12, width: 12 }} />
                            }
                            sx={{
                              padding: '2px 8px',
                              fontSize: 12,
                            }}>
                            Search Deals
                          </OrangeOutlinedButton>
                        </div>
                      ) : null}
                      {isAccomodation && accomodationProps?.bookingId && (
                        <div style={{ flex: 0, marginLeft: 'auto' }}>
                          <OrangeOutlinedButton
                            onClick={accomodationProps?.handleOpenBookingsModal}
                            sx={{
                              padding: '2px 8px',
                              fontSize: 12,
                              color:
                                accomodationProps?.bookingStatus === 'CANCELED'
                                  ? 'error.main'
                                  : 'success.main',
                              backgroundColor:
                                accomodationProps?.bookingStatus === 'CANCELED'
                                  ? '#FBD7D7'
                                  : '#D6FBD7',
                              border: 'none',
                            }}>
                            {!accomodationProps?.bookingStatus ||
                            accomodationProps?.bookingStatus === 'SUCCESS'
                              ? 'Booked'
                              : accomodationProps?.bookingStatus === 'CANCELED'
                              ? 'Canceled'
                              : 'Pending'}
                          </OrangeOutlinedButton>
                        </div>
                      )}
                    </div>
                  )}
                </Box>
                {(isAccomodation || isFlight) && (
                  <Divider
                    sx={{
                      display:
                        transportationType === 'flight' &&
                        !flightProps?.showHeader
                          ? 'none'
                          : 'auto',
                    }}
                  />
                )}
                <div style={{ display: 'flex' }}>
                  {isAccomodation &&
                  (showCustomEntry ||
                    accomodationProps?.checkinDate ||
                    accomodationProps?.checkoutDate) ? (
                    <Grid container className={classes.accomodationActions}>
                      <Grid item container alignItems="center">
                        <Typography className={classes.grayText}>
                          Check in:
                        </Typography>
                        <AccomodationDatePicker
                          defaultDate={accomodationProps?.checkinDate}
                          updateDate={accomodationProps?.updateCheckinDate}
                          maxDate={
                            accomodationProps?.checkoutDate &&
                            parseISODate(accomodationProps?.checkoutDate, true)
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        style={{ marginTop: 4 }}>
                        <Typography className={classes.grayText}>
                          Check out:
                        </Typography>
                        <AccomodationDatePicker
                          defaultDate={accomodationProps?.checkoutDate}
                          updateDate={accomodationProps?.updateCheckoutDate}
                          minDate={
                            accomodationProps?.checkinDate &&
                            parseISODate(accomodationProps?.checkinDate, true)
                          }
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                  {accomodationProps?.isBooking ? (
                    <div style={{ marginTop: 8 }}>
                      <Button
                        onClick={() =>
                          dispatch(
                            actions.Trips.setActiveBookingId({
                              tripId,
                              bookingId: accomodationProps?.bookingId,
                            })
                          )
                        }
                        sx={{
                          marginLeft: 'auto',
                          flexWrap: 'nowrap',
                          textWrap: 'nowrap',
                          padding: '2px 12px',
                          fontSize: 12,
                          pointerEvents: 'auto',
                        }}>
                        Manage Booking
                      </Button>
                    </div>
                  ) : null}
                </div>
                {isFlight && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: 8,
                      marginLeft: 4,
                    }}>
                    {transportationType === 'flight' ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: 12,
                          alignItems: 'center',
                        }}>
                        <ConfirmationNumberRounded
                          className={classes.transportIcon}
                          style={{
                            color:
                              transportationPickerActive === 'FLIGHT_NUMBER'
                                ? '#ED702E'
                                : '#4E4E4E',
                          }}
                        />
                        <InputBase
                          fullWidth
                          value={flightNumber}
                          onChange={(e) => setFlightNumber(e.target.value)}
                          onBlur={(e) => {
                            updateNameCallback(e.target.value);
                            setTransportationPickerActive(null);
                          }}
                          onFocus={() =>
                            setTransportationPickerActive('FLIGHT_NUMBER')
                          }
                          placeholder="AC 8191 (Search by airline code + flight number)"
                          className={classes.flightInput}
                          inputProps={{
                            className: [classes.inputPlaceholder],
                            style: {
                              padding: 0,
                            },
                          }}
                        />
                      </div>
                    ) : null}

                    {[0, 1].map((idx) => (
                      <Grid
                        container
                        key={idx}
                        style={{
                          marginTop: 8,
                          alignItems: 'center',
                        }}>
                        <Grid item className={classes.flightIcon}>
                          <div className={classes.iconContainer}>
                            {idx === 0 ? (
                              <DepartureCityIcon
                                isIconActive={
                                  transportationPickerActive ===
                                  'DEPARTURE_PICKER'
                                }
                              />
                            ) : transportationType === 'flight' ? (
                              <FlightLandRounded
                                className={classes.transportIcon}
                                style={{
                                  color:
                                    transportationPickerActive ===
                                    'ARRIVAL_PICKER'
                                      ? '#ED702E'
                                      : '#4E4E4E',
                                }}
                              />
                            ) : (
                              <LocationPinIcon
                                className={classes.transportIcon}
                                fill={
                                  transportationPickerActive ===
                                  'ARRIVAL_PICKER'
                                    ? '#ED702E'
                                    : '#4E4E4E'
                                }
                                style={{
                                  height: 18,
                                  width: 18,
                                }}
                              />
                            )}
                          </div>
                        </Grid>
                        <Grid
                          item
                          style={{
                            marginLeft: 4,
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                          }}>
                          <TransportationCityPicker
                            onChange={(airport) => {
                              flightProps?.handleUpdateConnection({
                                content: {
                                  [idx === 0 ? 'fromAirport' : 'toAirport']:
                                    transportationType === 'flight'
                                      ? airport?.icao
                                      : airport,
                                },
                              });
                            }}
                            defaultAirport={
                              idx === 0
                                ? flightProps?.content?.fromAirport
                                : flightProps?.content?.toAirport
                            }
                            placeholder={
                              idx === 0 ? 'Departure City' : 'Arrival City'
                            }
                            handleFocus={() =>
                              setTransportationPickerActive(
                                idx === 0
                                  ? 'DEPARTURE_PICKER'
                                  : 'ARRIVAL_PICKER'
                              )
                            }
                            handleBlur={() =>
                              setTransportationPickerActive(null)
                            }
                            transportationType={transportationType}
                          />
                        </Grid>
                        <Grid item>
                          <TransportationDatePicker
                            handleSubmit={(date) => {
                              flightProps?.handleUpdateConnection({
                                content: {
                                  [idx === 0 ? 'startDate' : 'endDate']:
                                    removeTimezoneOffset(date)?.toISOString(),
                                },
                              });
                            }}
                            defaultDate={
                              idx === 0
                                ? flightProps?.content?.startDate
                                : flightProps?.content?.endDate
                            }
                            flightVersion={flightProps?.version}
                            {...(idx !== 0
                              ? {
                                  minDate:
                                    flightProps?.content?.startDate &&
                                    parseISODate(
                                      flightProps?.content?.startDate,
                                      flightProps?.content?.version
                                    ),
                                }
                              : null)}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                )}
                {transportationType === 'flight' && flightProps?.isNewFlight ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 4,
                    }}>
                    <Typography className={classes.grayText}>OR</Typography>
                    <FlightSearchToggle />
                  </div>
                ) : (
                  <div className={className}>
                    <InputBase
                      value={bladeNotes}
                      placeholder="Notes"
                      fullWidth
                      multiline
                      onChange={(e) => setBladeNotes(e.target.value)}
                      onBlur={(e) => updateNotesCallback(e.target.value)}
                      style={{ padding: '0px 4px' }}
                      inputProps={{
                        className: classes.font,
                        style: { fontSize: '12px', marginLeft: '4px' },
                      }}
                    />
                    <IconsDrawer
                      hasLocation={hasLocation && !showAddress}
                      handleAddLocation={() => setLocationInput(true)}
                      hasDate={hasDate}
                      dateVal={dateVal}
                      updateDateCallback={updateDateCallback}
                      hasCost={hasCost}
                      costVal={costVal} // costVal object should have the following properties : amount, currency and per
                      perList={[
                        isAccomodation ? 'per night' : 'per person',
                        'total',
                      ]}
                      updateCostCallback={updateCostCallback}
                      hasLink={hasLink}
                      linkVal={linkVal}
                      updateLinkCallback={updateLinkCallback}
                      hasCustomCalendar={
                        !accomodationProps?.checkinDate &&
                        !accomodationProps?.checkoutDate &&
                        hasCustomCalendar
                      }
                      itemId={id}
                      updateCalendarClickCallback={() => setCustomEntry(true)}
                      fileAttachmentModalProps={fileAttachmentModalProps}
                      activityVersion={activityVersion}
                      trackFileView={trackFileView}
                    />
                  </div>
                )}
                {transportationType === 'flight' &&
                  !flightProps?.isNewFlight &&
                  !isLoading && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 4,
                      }}>
                      <div
                        style={{
                          height: '1px',
                          width: '100%',
                          border: '1px dashed #E4E4E4',
                          marginBottom: 8,
                        }}
                      />
                      <Typography className={classes.grayText}>OR</Typography>
                      <FlightSearchToggle />
                    </div>
                  )}
              </Box>
            )}
          </Box>
        </Paper>
      </Fade>
    </ClickAwayListener>
  );
}

export default InlineBlade;
