import { useEffect, useRef, useState } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Popper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Divider,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  ArrowDropDownRounded,
  ChevronRightRounded,
  AddCircleOutlineRounded,
  StarRounded,
} from '@mui/icons-material';
import { formatNumber } from '../../../../../../utils';
import {
  ButtonDefault,
  SecondaryOutlinedButton,
} from '../../../../../atoms/Button/index';
import actions from '../../../../../../redux/actions';
import config from '../../../../../config';
import { EVENTS, phTrackEvent } from '../../../../../../analytics';
import { GoogleMapsIcon } from '../../../../../atoms/Icon';
import { useMapUtils } from '../../../Map';
import ITEM_TYPES from '../../../../../../const';
import { createItem } from '../../../../../../redux/slices/Item';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    height: 'auto',
    width: '550px',
    border: '1px solid #D9D9D9',
    boxShadow: 'none',
    marginBottom: 16,
  },
  image: {
    flex: '0 0 auto',
    objectFit: 'cover',
    width: 140,
    height: 'auto',
    maxHeight: (props) => props.cardHeight,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headingContainer: {
    fontSize: '14px',
    fontWeight: 500,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    height: 28,
    fontSize: '12px',
    padding: '8px 8px',
    borderRadius: '4px',
  },
  popper: {
    zIndex: 1121,
    width: 'inherit',
  },
  list: {
    maxHeight: 160,
    overflowY: 'auto',
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
    padding: '4px 0px',
    marginTop: 8,
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 12,
    },
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
  },
  listItem: {
    minWidth: 120,
    padding: '4px 12px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      '& .MuiListItemIcon-root': {
        color: '#ED702E !important',
      },
    },
  },
});

function ItemDropDown({
  tripId,
  handleClickAway = () => {},
  handleAddItem = () => {},
  destinationClassSelector = null,
}) {
  const classes = useStyles();
  const [destinationAnchor, setDestinationAnchor] = useState(null);
  const [destinationPopperOpen, setDestinationPopperOpen] = useState(null);
  const items = useSelector((state) => state.Item.items);
  const firstLevelDestinations = useSelector((state) =>
    state.tripsAtc.tripsAtc[tripId]?.children?.filter(
      (itemId) => items[itemId]?.type === ITEM_TYPES.DESTINATION
    )
  );

  const handleLocationHover = (e, id) => {
    setDestinationAnchor(e.currentTarget);

    if (destinationPopperOpen === id) return;
    setDestinationPopperOpen(id);
  };

  const handleItemClick = (parentId, type) => {
    handleAddItem(parentId, type);
    handleClickAway();
  };

  return (
    firstLevelDestinations &&
    firstLevelDestinations.map((destinationId) => {
      const destination = items[destinationId];
      if (!destination) return null;
      const { id, title } = destination;

      const secondLevelHeadings = destination?.children?.filter(
        (itemId) => items[itemId]?.type === ITEM_TYPES.HEADING
      );

      return (
        <ListItem
          key={id}
          onClick={() => handleItemClick(id, ITEM_TYPES.DESTINATION)}
          id="add-to-trip-button"
          onMouseOver={(e) => handleLocationHover(e, id)}
          onMouseLeave={() => {
            if (secondLevelHeadings?.length === 0) {
              setDestinationPopperOpen(null);
            }
          }}
          className={`${classes.listItem} ${destinationClassSelector || ''}`}
          style={{
            // to align the text, 12px(specified) + 2px(alignment spacing) with the 'new location' icon according to the designs
            paddingLeft: 14,
          }}
          alignItems="center">
          <ListItemText
            primary={title}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
          {secondLevelHeadings?.length > 0 && (
            <>
              <ListItemIcon
                style={{
                  minWidth: 0,
                }}>
                <ChevronRightRounded
                  style={{
                    height: 14,
                    width: 14,
                    ...(destinationPopperOpen === id
                      ? {}
                      : { color: 'transparent' }),
                  }}
                />
              </ListItemIcon>
              <Popper
                id={`location-${id}`}
                open={destinationPopperOpen === id}
                anchorEl={destinationAnchor}
                placement="right"
                style={{
                  width: 'inherit',
                  zIndex: 9122,
                  visibility: !destinationAnchor ? 'hidden' : 'visible',
                }}
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [-4, 8],
                    },
                  },
                ]}>
                <ClickAwayListener onClickAway={() => {}}>
                  <List
                    className={classes.list}
                    style={{
                      maxHeight: 100,
                      maxWidth: 120,
                    }}
                    onMouseLeave={() => {
                      setDestinationPopperOpen(null);
                    }}>
                    {secondLevelHeadings?.map((headingId) => {
                      const heading = items[headingId];
                      return (
                        <ListItem
                          key={headingId}
                          onClick={() =>
                            handleItemClick(headingId, ITEM_TYPES.HEADING)
                          }
                          className={classes.listItem}
                          justify="center"
                          style={{ padding: '2px 12px' }}>
                          <ListItemText
                            primary={heading?.title ?? 'Unnamed Section'}
                            primaryTypographyProps={{ fontSize: '14px' }}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </ClickAwayListener>
              </Popper>
            </>
          )}
        </ListItem>
      );
    })
  );
}

function ActivityCard({
  description,
  placesData,
  tripId,
  setToastMessage,
  setShowSnackBar,
}) {
  const { getPlaceDetails, createMapPinForPlace, extractAddressComponents } =
    useMapUtils();
  const dispatch = useDispatch();
  const commandBarState = useSelector((state) => state.View.commandBarV2);
  const items = useSelector((state) => state.Item.items);
  const ref = useRef(null);
  const hoverTimeRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { activeLocationId, activeSectionId } = commandBarState;
  const cardRef = useRef(null);
  const [cardHeight, setCardHeight] = useState('160px');
  const classes = useStyles({ cardHeight });

  const setLocationPopperOpen = (value) =>
    dispatch(actions.View.setLocationPopperOpen(value));

  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.offsetHeight);
    }
  }, [showFullDescription]);

  useEffect(async () => {
    const { maps } = await getPlaceDetails(
      placesData?.place_id,
      'ACTIVITY',
      true
    );
    setMapData(maps);
  }, []);

  const fallBackUrl = '/images/DefaultMapPopupBanner.png';
  const [imgUrl, setImgUrl] = useState(fallBackUrl);

  const activityImg = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${placesData?.photoRef}&key=${config.googlePlacesKey}`;

  const img = new Image();
  img.src = activityImg;
  img.onload = () => {
    if (img.height === 100 && img.width === 100) {
      setImgUrl(fallBackUrl);
    } else {
      setImgUrl(activityImg);
    }
  };

  const handleSeeFullDetails = async () => {
    phTrackEvent({
      event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_SEE_DETAIL_CLICK,
    });
    const { title } = await getPlaceDetails(placesData?.place_id, 'ACTIVITY');
    phTrackEvent({
      event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_SEE_DETAIL_CLICK_ACTIVITY}${title}`,
    });
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: false,
      })
    );
  };

  const handleDropDownClick = (e) => {
    e.stopPropagation();
    setAnchorEl(ref?.current);
    setOpen(!open);
  };

  const handleAddLocation = async () => {
    const details = await getPlaceDetails(placesData?.place_id, 'ACTIVITY');
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: false,
      })
    );
    const mapPinId = await createMapPinForPlace(details, 'LOCATION');
    await dispatch(
      createItem({
        variables: {
          type: ITEM_TYPES.DESTINATION,
          title: details.title,
          mapPin: mapPinId,
          tripId,
          index: 0,
        },
        index: 0,
        tripId,
        parentId: tripId,
      })
    );
  };

  const handleAddActivity = async (
    locationId,
    sectionId,
    sectionName,
    locationName
  ) => {
    const {
      title,
      photo,
      rating,
      website,
      maps,
      ratingCount,
      long,
      lat,
      types,
      categoryId,
      openingHoursObj,
      addressComponents,
      formattedAddress,
      formattedPhoneNumber,
    } = await getPlaceDetails(placesData?.place_id, 'ACTIVITY');
    dispatch(
      actions.View.setCommandBar({
        isRecommendationsOpen: false,
      })
    );
    phTrackEvent({
      event: EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_MANUAL_ADD,
    });
    phTrackEvent({
      event: `${EVENTS.PLAN_UNIVERESAL_ADD.SUGGEST_RESULTS_MANUAL_ADD_ACTIVITY}${title}`,
    });
    dispatch(
      actions.View.setHighlightedSection({
        section: null,
      })
    );
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
    const mapPin = await createMapPinForPlace(
      {
        title,
        description,
        photo,
        rating,
        website,
        maps,
        ratingCount,
        long,
        lat,
        types,
        placeId: placesData?.place_id,
        categoryId,
      },
      'ACTIVITY',
      {
        openingHoursObj,
        addressComponents,
        formattedAddress,
        formattedPhoneNumber,
        maps,
        website,
      }
    );
    const address = extractAddressComponents(addressComponents);
    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });
    if (sectionId === null) {
      const headingId = (
        await dispatch(
          createItem({
            variables: {
              type: ITEM_TYPES.HEADING,
              title: 'Things to do',
              tripId,
              parentId: locationId,
              index: 0,
            },
            index: 0,
            parentId: locationId,
            tripId,
          })
        )
      )?.payload?.createItem?.id;
      await dispatch(
        createItem({
          variables: {
            type: ITEM_TYPES.ACTIVITY,
            title,
            mapPin: mapPin?.id,
            content: {
              address,
            },
            parentId: headingId,
            tripId,
            index: 0,
          },
          index: 0,
          tripId,
          parentId: headingId,
        })
      );
      setToastMessage(`Added to ${locationName}`);
      setShowSnackBar(true);
    } else {
      await dispatch(
        createItem({
          variables: {
            type: ITEM_TYPES.ACTIVITY,
            title,
            mapPin: mapPin?.id,
            content: {
              address,
            },
            parentId: sectionId,
            tripId,
            index: 0,
          },
          index: 0,
          tripId,
          parentId: sectionId,
        })
      );
      if (sectionName) {
        setToastMessage(`Added to ${locationName} > ${sectionName}`);
      } else {
        setToastMessage(`Added to ${locationName}`);
      }
      setShowSnackBar(true);
    }
  };

  const handleDropDownHover = (e) => {
    e.stopPropagation();
    clearTimeout(hoverTimeRef.current);
    if (!open) {
      setAnchorEl(ref?.current);
      setOpen(true);
    }
  };

  const formatString = (str) => {
    return str
      .replace(/_/g, ' ')
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleDropDownLeave = (e) => {
    e.stopPropagation();
    // Adding delay to avoid flickering
    hoverTimeRef.current = setTimeout(() => {
      setAnchorEl(null);
      setOpen(false);
      setLocationPopperOpen(null);
    }, 300);
  };

  const handleAddItem = async (sourceId, type) => {
    const {
      title,
      photo,
      rating,
      website,
      maps,
      ratingCount,
      long,
      lat,
      types,
      categoryId,
      openingHoursObj,
      addressComponents,
      formattedAddress,
      formattedPhoneNumber,
    } = await getPlaceDetails(placesData?.place_id, 'ACTIVITY');
    // addMapPin
    const mapPin = await createMapPinForPlace(
      {
        title,
        description,
        photo,
        rating,
        website,
        maps,
        ratingCount,
        long,
        lat,
        types,
        placeId: placesData?.place_id,
        categoryId,
      },
      'ACTIVITY',
      {
        openingHoursObj,
        addressComponents,
        formattedAddress,
        formattedPhoneNumber,
        maps,
        website,
      }
    );
    const address = extractAddressComponents(addressComponents);

    if (window?.heap)
      window?.heap.track('Activity Created', {
        source: 'Map',
      });
    let parentId = sourceId;
    if (type === ITEM_TYPES.HEADING) {
      const headingId = (
        await dispatch(
          createItem({
            variables: {
              type: ITEM_TYPES.HEADING,
              title: 'Things to do',
              tripId,
              parentId: sourceId,
              index: 0,
            },
            index: 0,
            tripId,
            parentId: sourceId,
          })
        )
      )?.payload?.createItem?.id;
      parentId = headingId;
    }

    dispatch(
      createItem({
        variables: {
          type: ITEM_TYPES.ACTIVITY,
          title,
          mapPin: mapPin?.id,
          content: {
            address,
          },
          parentId,
          tripId,
          index: 0,
        },
        index: 0,
        tripId,
        parentId,
      })
    );
  };

  const popperId = open ? 'search-location-popper' : undefined;

  const handleClickAway = () => {
    setOpen(false);
    setLocationPopperOpen(null);
  };

  return (
    <>
      <Card className={classes.card} ref={cardRef}>
        <CardMedia
          component="img"
          image={imgUrl || fallBackUrl}
          alt={placesData?.pinName}
          className={classes.image}
        />
        <CardContent className={classes.content}>
          <Typography className={classes.headingContainer}>
            {placesData?.pinName}
          </Typography>
          <Typography
            sx={{
              color: '#4E4E4E',
              fontSize: '12px',
              fontWeight: 500,
              marginBottom: '6px',
            }}>
            {placesData?.types && formatString(placesData?.types[0])}
          </Typography>
          <Box
            sx={{
              position: 'relative',
            }}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: showFullDescription ? 'none' : 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {description}
            </Typography>
            {!showFullDescription && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  background: 'white',
                  display: 'inline-flex',
                }}>
                <Box
                  component="span"
                  sx={{
                    marginRight: '4px',
                    color: '#4E4E4E',
                    textDecoration: 'none',
                    fontSize: '14px',
                  }}>
                  ...
                </Box>
                <Box
                  component="span"
                  sx={{
                    color: '#4E4E4E',
                    cursor: 'pointer',
                    fontWeight: 600,
                    fontSize: '14px',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => setShowFullDescription((prev) => !prev)}>
                  See more
                </Box>
              </Box>
            )}
          </Box>

          <Box className={classes.buttons}>
            <Box className={classes.rating}>
              {mapData && (
                <IconButton
                  sx={{ padding: 0, marginRight: '4px' }}
                  onClick={() => window.open(mapData, '_blank')}
                  disableFocusRipple
                  disableRipple>
                  <GoogleMapsIcon />
                </IconButton>
              )}
              {placesData?.user_ratings_total > 0 && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  sx={{ display: 'flex', alignItems: 'center' }}>
                  <StarRounded
                    sx={{
                      verticalAlign: 'middle',
                      color: '#ED702E',
                      height: 20,
                      width: 20,
                    }}
                  />
                  {placesData?.rating} (
                  {formatNumber(placesData?.user_ratings_total || 0)})
                </Typography>
              )}
            </Box>
            <Box>
              <SecondaryOutlinedButton
                className={classes.buttonContainer}
                onClick={handleSeeFullDetails}
                sx={{
                  marginRight: '3px',
                  '&:hover': {
                    backgroundColor: '#D9D9D9',
                    borderColor: '#D9D9D9',
                    color: 'inherit',
                    '& .MuiButton-startIcon': {
                      '& svg': {
                        color: 'inherit',
                      },
                    },
                  },
                }}>
                See details
              </SecondaryOutlinedButton>
              <ButtonGroup
                sx={{
                  boxShadow: 'none',
                  '& .MuiButtonGroup-grouped': {
                    minWidth: 0,
                  },
                }}>
                <ButtonDefault
                  className={classes.buttonContainer}
                  disableFocusRipple
                  disableRipple
                  onClick={() => {
                    if (!activeLocationId) {
                      handleAddLocation();
                    } else {
                      handleAddActivity(
                        activeLocationId,
                        activeSectionId,
                        items[activeSectionId]?.title,
                        items[activeLocationId]?.title
                      );
                    }
                  }}
                  sx={{
                    '& .MuiButton-endIcon': {
                      margin: '0px !important',
                      padding: '0px !important',
                    },
                  }}>
                  Add to trip
                </ButtonDefault>
                <ButtonDefault
                  ref={ref}
                  onClick={handleDropDownClick}
                  size="small"
                  sx={{
                    height: 28,
                    fontSize: '12px',
                    padding: '2px 2px',
                    borderRadius: '4px',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      left: '0',
                      top: '30%',
                      height: '40%',
                      width: '1px',
                      backgroundColor: 'white',
                    },
                  }}>
                  <ArrowDropDownRounded sx={{ padding: 0, margin: 0 }} />
                </ButtonDefault>
              </ButtonGroup>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Popper
        id={popperId}
        open={open}
        placement="right"
        anchorEl={anchorEl}
        className={classes.popper}
        onMouseOver={handleDropDownHover}
        onMouseLeave={handleDropDownLeave}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <List className={classes.list}>
            <ListItem
              key="create-new-location"
              onClick={async () => {
                await handleAddLocation();
                handleClickAway();
              }}
              onMouseOver={() => {
                setLocationPopperOpen('create-new-location');
              }}
              className={classes.listItem}
              style={{
                whiteSpace: 'nowrap',
                justifyContent: 'flex-start',
              }}>
              <ListItemIcon
                style={{
                  minWidth: 0,
                  marginRight: 4,
                }}>
                <AddCircleOutlineRounded
                  style={{
                    height: 14,
                    width: 14,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary="New Location"
                primaryTypographyProps={{
                  fontSize: '14px',
                }}
              />
            </ListItem>
            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
            <ItemDropDown
              tripId={tripId}
              handleClickAway={handleClickAway}
              handleAddItem={handleAddItem}
            />
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

export default ActivityCard;
